.projects-main {
  background-color: $background-color;
  width: 100%;
  height: calc(100vh - 80px);
  @include flex(flex-start);
  flex-flow: row wrap;
}

.projects-controls, .projects-info-title {
  padding-top: 20px;
  padding-bottom: 15px;
  min-height: 245px;
}

.projects-list-wrapper, .projects-info-wrapper {
  @media (max-width: 768px) {
    height: auto;
  }
}

.projects-list-wrapper {
  padding: 20px !important;
  @include split-div(15%);
  margin-bottom: 10px;
}

.projects-info-wrapper {
  padding: 20px !important;
  @include split-div(75%);
  margin-bottom: 10px;
}

.projects-list-interaction-wrapper {
  display:flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.projects-list__search {
  display:flex;
  width:100%;
  .projects-list__search-field {
    width: 100%;
  }
}

.projects-list__add-wrapper{
  width:100%;
  margin-top:15px;
  @include flex(center, center);
}

.projects-list__add {
  width:150px;
  @include flex(flex-end, flex-end);
}

.projects-list__recent-activity {
  margin-bottom: 0.7rem;
}

.filters-wrapper {
  flex: 1;
  display: inline-block;
}

.filters-select {
  margin-top: 15px;
  width: 100%;
}

.projects-list {
  border-radius: 0.75rem !important;
  @include maxHeight;

}

.projects-info {
  @include card;
}

.projects-list-item {
  display: block;
  height: 35px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 220px;
  }
}

.indent {
  font-size: .8em;
  margin-right: 25px;
}

.projects-list__select-technologies {
  width: 100%;
}

.projects-info__details {
  @include scrollable-content;
  @media (min-width: 768px) {
    height:100px;
  }
}

.projects-list__details {
  display: flex;
  flex-direction: column;
  padding-top:0.9rem !important;
  height:max-content;
  
  @include scrollable-content;
  @media (min-width: 768px) {
    height:115px;
  }
}

.loading {
  padding: 15px;
  text-align: center;
}

.projects-nodata {
  padding: 15px;
  margin-top: 0px;
  height: auto;
}

.projects-info_min-height {
  height: auto;
}

#left {
  float: left;
}

#right {
  float: right;
}

.list-header {
  padding: 10px calc(1.75rem + 16px);
}

.project-access-request-wrap {
  padding: 0 0 10px 0;

  .project-access-request-title {
    margin: 5px 5px 5px 15px;
    display: flow-root;
  }
}

.project-access-request-title__name {
  display: flex;
  align-items: center;
  min-height: 36px;
  margin: 5px !important;
}
