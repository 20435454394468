@import '../../mixins';

.BillableHours{
    &-wrapper{
        width: 100%;
        margin-top: 20px;
        padding-top: 10px;
        font-size: 16px;
        min-height: fit-content;
    }
    &-hours{
        @include flex();
    }
    &-table{
        width: 100%;
        overflow-x: auto;
        max-width: 100vw;
    }
    &-cell{
        padding-right: 5px !important;
        padding-left: 5px !important;

        &-billable-time {
            padding-right: 5px !important;
            padding-left: 5px !important;
            text-align: center !important;
            background-color: #d4edda !important;
        }
        &-zero-time {
            padding-right: 5px !important;
            padding-left: 5px !important;
            text-align: center !important;
            background-color: #E0E0E0;
        }
        &-totals-old {
            font-weight: bold !important;
            white-space: nowrap !important;
            text-align: center !important;
            min-width: 90px;
        }
        &-totals-new {
            font-weight: bold !important;
            white-space: nowrap !important;
            text-align: center !important;
            background-color: #e0f7fa !important;
            min-width: 90px;
        }
        &-days {
            text-align: center !important;
        }
        &-days-new {
            text-align: center !important;
        }
    }
    &-input{
        width: 65px;

        input {
            text-align: center;
          }
    }
    &-row{
        .BillableHours-cell:first-child{
            padding-left:14px !important;
        }
        .BillableHours-cell:last-child{
            padding-right:14px !important;
        }
    }
    &-control-wrpr {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    &-checkbox {
        padding: 0;
    }
    &-switch {
        display: flex;
        justify-content: flex-end;
    }
    &-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}
