.report-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-form {
  width: 100%;
  margin-top: 15px;
}

.report__save-button, .report__load-from-jira, .report__error, .report__save-status {
  margin-top: 10px !important;
  border-radius: 4px !important;
}

.report__error, .report__save-status {
  width: 100%;
}

.report__save-status {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.report__error-list {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.report-project-select-block {
  padding-top: 15px;
}

.MuiInputLabel-outlined {
  z-index: 0 !important;
}
